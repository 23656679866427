import { Flex, HStack, Icon, IconButton, Text, VStack } from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { IQoute } from 'services/@types';
const fields = [
  { name: 'firstName', label: 'create_quote.name' },
  { name: 'lastName', label: 'create_quote.last_name' },
  { name: 'phone', label: 'create_quote.phone' },
  { name: 'email', label: 'create_quote.email' },
  { name: 'idNumber', label: 'create_quote.id' },
];

export default function CustomerDetails({
  handleAddCustomer,
  error,
  newQuote,
  setNewQuote,
}: {
  handleAddCustomer: () => void;
  error: {
    customerName: boolean;
    products: boolean;
    totalPayment: boolean;
  };
  newQuote: IQoute;
  setNewQuote: React.Dispatch<React.SetStateAction<IQoute>>;
}) {
  const { t } = useTranslation();

  const memoizedCustomerDetails = useMemo(() => {
    return newQuote.customerDetails;
  }, [newQuote.customerDetails]);

  return (
    <VStack alignItems="start" gap="16px">
      {memoizedCustomerDetails?.map((customer, index) => (
        <React.Fragment key={index}>
          <Text variant="createQuoteTitle">
            {t('create_quote.customer_details')}
            {index > 0 ? ` #${index + 1}` : null}
          </Text>
          <Flex wrap="wrap" gap="12px" width="100%">
            {fields.map((field) => (
              <InputField
                key={field.name}
                label={t(field.label)}
                placeholder={t(field.label)}
                value={customer[field.name as keyof typeof customer]}
                borderColor={
                  error.customerName && field.name === 'firstName'
                    ? 'error.100'
                    : 'gray.200'
                }
                flex="1"
                minWidth={{ base: '100%', md: '260px' }}
                w={{ base: '100%', md: '260px' }}
                onChange={(e) =>
                  setNewQuote((prev) => ({
                    ...prev,
                    customerDetails: prev.customerDetails.map((c, key) =>
                      index === key
                        ? { ...c, [field.name]: e.target.value }
                        : c,
                    ),
                  }))
                }
              />
            ))}
          </Flex>
        </React.Fragment>
      ))}
      {memoizedCustomerDetails?.length < 2 && (
        <HStack>
          <IconButton
            borderRadius="10px"
            icon={<Icon as={FiPlus} color="white" w="30px" h="30px" />}
            onClick={handleAddCustomer}
            aria-label="add"
            w="33px"
            h="33px"
            minW="33px"
            bg="#93C3B3"
          />
          <Text>{t('create_quote.add_customer')}</Text>
        </HStack>
      )}
    </VStack>
  );
}
