import {
  VStack,
  HStack,
  Icon,
  Text,
  Spacer,
  Box,
  Button,
} from '@chakra-ui/react';
import { LuUndo2 } from 'react-icons/lu';
import { useTranslation } from 'react-i18next';
import { RenderProps } from '@chakra-ui/toast';

interface CancellableAlertToastProps {
  title: string;
  description?: string;
  icon: React.ElementType;
  onUndo?: () => void;
}

export const CancellableAlertToast: React.FC<
  CancellableAlertToastProps & RenderProps
> = ({ title, description, icon, onUndo, ...props }) => {
  const { t } = useTranslation();

  return (
    <VStack
      p={2}
      w="100%"
      pe={4}
      maxW="500px"
      spacing={0}
      borderRadius="xl"
      align="flex-start"
      justify="flex-start"
      bg="brand.200"
      color="primary.100">
      <HStack spacing={2} justify="flex-start" align="center" w="100%">
        <Icon color="white" as={icon} fontSize="xl" />
        <VStack spacing={0} align="flex-start" justify="flex-start">
          <Text fontWeight="500" noOfLines={1} color="white">
            {t(title)}
          </Text>
          {description && (
            <Text fontSize="sm" fontWeight="medium" noOfLines={2}>
              {description}
            </Text>
          )}
        </VStack>
        <Spacer />
        {onUndo && (
          <Box>
            <Button
              aria-label="undo"
              variant="h3white"
              onClick={(e) => {
                e.stopPropagation();
                onUndo();
              }}>
              <HStack>
                <Icon as={LuUndo2} />
                <Text fontWeight="500">{t('tasks.toast.undo')}</Text>
              </HStack>
            </Button>
          </Box>
        )}
      </HStack>
    </VStack>
  );
};
