import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  SimpleGrid,
  Box,
  Text,
  Textarea,
  useBreakpointValue,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeReportUserDialog } from 'contexts/redux/dialog/dialogsSlice';
import {
  LuAlertTriangle,
  LuBan,
  LuMessageSquare,
  LuShield,
  LuUserX,
} from 'react-icons/lu';
import { RiSpam2Fill } from 'react-icons/ri';
import { RootState } from 'contexts/redux/store';

const reportReasons = [
  {
    id: 'harassment',
    icon: LuMessageSquare,
    translationKey: 'messenger.report.reasons.harassment',
  },
  {
    id: 'spam',
    icon: RiSpam2Fill,
    translationKey: 'messenger.report.reasons.spam',
  },
  {
    id: 'inappropriate',
    icon: LuAlertTriangle,
    translationKey: 'messenger.report.reasons.inappropriate',
  },
  {
    id: 'fake',
    icon: LuUserX,
    translationKey: 'messenger.report.reasons.fake',
  },
  {
    id: 'harmful',
    icon: LuShield,
    translationKey: 'messenger.report.reasons.harmful',
  },
  {
    id: 'other',
    icon: LuBan,
    translationKey: 'messenger.report.reasons.other',
  },
];

export const ReportUserDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const [customReason, setCustomReason] = useState('');
  const isMobile = useBreakpointValue({ base: true, md: false });
  const toast = useToast();

  // Get dialog state from Redux
  const { reportUserDialog } = useSelector((state: RootState) => state.dialogs);
  const reportedUserName = reportUserDialog?.item?.userName;

  const handleClose = useCallback(() => {
    dispatch(closeReportUserDialog());
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    if (selectedReason) {
      // Handle the report submission here
      console.log('Report submitted:', {
        reason: selectedReason,
        customReason: selectedReason === 'other' ? customReason : undefined,
        reportedUserName,
      });

      // TODO: Dispatch an action to submit the report
      // dispatch(submitUserReport({
      //   reason: selectedReason,
      //   customReason: selectedReason === 'other' ? customReason : undefined,
      //   reportedUserId: item.userId
      // }));

      toast({
        title: t('toast.title.success'),
        description: t('toast.description.report_submitted'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });

      handleClose();
    }
  }, [selectedReason, customReason, reportedUserName, handleClose, toast, t]);

  return (
    <Modal
      isOpen={!!reportUserDialog?.item}
      onClose={handleClose}
      size={isMobile ? 'full' : 'xl'}
      motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent
        borderRadius={isMobile ? '0' : '16px'}
        m={isMobile ? '0' : '4'}
        h={isMobile ? '100vh' : 'auto'}>
        <ModalHeader
          borderBottom="1px solid"
          borderColor="gray.100"
          p="16px 24px">
          <Text fontSize="lg" fontWeight="600">
            {t('messenger.report.title', { name: reportedUserName })}
          </Text>
        </ModalHeader>
        <ModalBody p="24px">
          <VStack spacing={6} align="stretch">
            <Text color="gray.600">{t('messenger.report.description')}</Text>
            <SimpleGrid columns={{ base: 2, md: 3 }} spacing={4}>
              {reportReasons.map((reason) => (
                <Box
                  key={reason.id}
                  p={4}
                  borderRadius="12px"
                  border="1px solid"
                  borderColor={
                    selectedReason === reason.id ? 'brand.500' : 'gray.200'
                  }
                  bg={selectedReason === reason.id ? 'brand.50' : 'white'}
                  cursor="pointer"
                  onClick={() => setSelectedReason(reason.id)}
                  transition="all 0.2s"
                  _hover={{
                    borderColor: 'brand.500',
                    bg: 'brand.50',
                  }}>
                  <VStack spacing={2}>
                    <reason.icon
                      size={24}
                      color={
                        selectedReason === reason.id ? '#8E7A70' : '#718096'
                      }
                    />
                    <Text
                      fontSize="sm"
                      fontWeight="500"
                      color={
                        selectedReason === reason.id ? 'brand.500' : 'gray.700'
                      }
                      textAlign="center">
                      {t(reason.translationKey)}
                    </Text>
                  </VStack>
                </Box>
              ))}
            </SimpleGrid>
            {selectedReason === 'other' && (
              <Textarea
                placeholder={t('messenger.report.custom_reason_placeholder')}
                value={customReason}
                onChange={(e) => setCustomReason(e.target.value)}
                resize="vertical"
                minH="100px"
                maxH="200px"
              />
            )}
          </VStack>
        </ModalBody>
        <ModalFooter borderTop="1px solid" borderColor="gray.100" p="16px 24px">
          <Button variant="ghost" mr={3} onClick={handleClose}>
            {t('dialogs.alert.cancel')}
          </Button>
          <Button
            colorScheme="red"
            onClick={handleSubmit}
            isDisabled={
              !selectedReason ||
              (selectedReason === 'other' && !customReason.trim())
            }>
            {t('messenger.report.submit')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
