import { Divider, Flex, Image, Text } from '@chakra-ui/react';

import logo from 'assets/img/quote/logo.png';
import dsdLogo from 'assets/img/quote/dsdLogo.png';

export default function Header() {
  return (
    <Flex
      justifyContent="space-between"
      flexDirection={{ base: 'column-reverse', md: 'row' }}
      alignItems={{ base: 'start', md: 'center' }}
      w="100%">
      <Flex flexDirection="column" gap={2}>
        <Text variant="previewQuoteHeaderTitle">Brilliant</Text>
        <Flex alignItems="center" gap="5px">
          <Text variant="previewQuoteHeaderSubTitle">
            50 Reading St. Tel Aviv-Yafo
          </Text>
          <Divider
            orientation="vertical"
            borderColor="#00000033"
            w="1px"
            h="16px"
          />
          <Text variant="previewQuoteHeaderSubTitle">052-8353758</Text>
        </Flex>
        <Flex alignItems="center" gap="5px">
          <Text variant="previewQuoteHeaderSubTitle">Brilliant@gmail.com</Text>
          <Divider
            orientation="vertical"
            borderColor="#00000033"
            w="1px"
            h="16px"
          />
          <Text variant="previewQuoteHeaderSubTitle">www.brilliant.co.il</Text>
        </Flex>
      </Flex>
      <Flex alignItems="center" gap="24px">
        <Image src={dsdLogo} alt="dsdLogo" maxH="80px" />
        <Image src={logo} alt="logo" maxH="38px" />
      </Flex>
    </Flex>
  );
}
