import {
  Flex,
  HStack,
  Icon,
  Image,
  Text,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { CancellableAlertToast } from 'components/cancellableAlertToast';
import { updateTask } from 'contexts/redux/task/taskSlice';
import { format } from 'date-fns';
import { enGB, he } from 'date-fns/locale';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegCircle, FaUndoAlt } from 'react-icons/fa';
import { FaCircleCheck } from 'react-icons/fa6';
import { GoCheckCircle } from 'react-icons/go';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ITask } from 'services/@types';
import EventService from 'services/event.api';
import ImportantFlag from '../../../../assets/svg/priority/important-flag.svg';

interface TaskCardProps {
  task: ITask;
  onClick?: (Task: ITask) => void;
  isCompleted?: boolean;
  [key: string]: any;
}
export default function TaskCard(props: TaskCardProps) {
  const { task, onClick, isCompleted, ...rest } = props;
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const toast = useToast();

  const { data: eventData } = useQuery({
    queryKey: [`getEvent-${task?.event}`],
    queryFn: () => EventService.getEvent(task?.event),
    enabled: !!task?.event,
  });

  const month = useMemo(
    () =>
      task?.dateAndTime
        ? format(new Date(task?.dateAndTime), 'MMM', {
            locale: i18n.language.startsWith('he') ? he : enGB,
          })
        : null,
    [task?.dateAndTime, i18n.language],
  );
  const date = useMemo(
    () =>
      task?.dateAndTime ? format(new Date(task?.dateAndTime), 'dd') : null,
    [task?.dateAndTime],
  );

  const handleUncompleteTask = useCallback(
    async (e: any) => {
      e.stopPropagation();
      dispatch(
        updateTask({
          id: task?.id,
          updates: { status: 'pending', completedAt: null },
        }),
      );

      toast({
        position: 'bottom-right',
        duration: 5000,
        render: (props) => (
          <CancellableAlertToast
            {...props}
            icon={FaUndoAlt}
            title="tasks.toast.task_uncompleted"
            description={t('tasks.toast.task_marked_pending', {
              name: task?.name,
            })}
            onUndo={() => {
              dispatch(
                updateTask({
                  id: task?.id,
                  updates: { status: 'completed', completedAt: new Date() },
                }),
              );
              toast.close(props.id);
            }}
          />
        ),
      });
    },
    [task?.id, task?.name, dispatch, toast, t],
  );

  const handleCompleteTask = useCallback(
    async (e: any) => {
      e.stopPropagation();
      dispatch(
        updateTask({
          id: task?.id,
          updates: { status: 'completed', completedAt: new Date() },
        }),
      );

      toast({
        position: i18n.dir() === 'rtl' ? 'top-left' : 'top-right',
        duration: 65000,
        render: (props) => (
          <CancellableAlertToast
            {...props}
            icon={GoCheckCircle}
            title="tasks.toast.task_completed"
            description={t('tasks.toast.task_marked_completed', {
              name: task?.name,
            })}
            onUndo={() => {
              dispatch(
                updateTask({
                  id: task?.id,
                  updates: { status: 'pending', completedAt: null },
                }),
              );
              toast.close(props.id);
            }}
          />
        ),
      });
    },
    [task?.id, task?.name, dispatch, toast, t],
  );

  return (
    <Flex
      pos="relative"
      w="100%"
      onClick={() => navigate(`/main/tasks/update/${task.id}`)}
      h="fit-content"
      {...rest}>
      <VStack
        width="100%"
        border="1px solid"
        borderColor={'#5FBF96'}
        borderRadius="16px"
        transition="all 0.3s ease"
        cursor="pointer"
        _hover={{ bg: '#F5F5F5' }}
        pb="20px"
        py={2}
        onClick={() => onClick && onClick(task)}>
        <Flex w="100%" gap={5} px={6} alignItems="center">
          <VStack textAlign="center" spacing={0} justifyContent="center">
            <Text
              variant="taskCardDate"
              fontSize="12px"
              color={isCompleted && '#00000066'}>
              {month}
            </Text>
            <Text variant="taskCardDate" color={isCompleted && '#00000066'}>
              {date}
            </Text>
          </VStack>
          <VStack alignItems="start" justifyContent="center" spacing={0}>
            <Text
              variant="eventCardTitle"
              color={isCompleted && '#00000066'}
              textDecoration={isCompleted && 'line-through #00000066'}>
              {task?.name}
            </Text>
            <Text
              variant="taskCardSubTitle"
              color={isCompleted && '#00000066'}
              textDecoration={isCompleted && 'line-through #00000066'}>
              {eventData?.name}
            </Text>
          </VStack>

          <HStack justifyContent="end" h="100%" flex="1" gap={2}>
            <Text variant="taskHistoryDate" alignSelf="start">
              {task?.completedAt
                ? format(new Date(task.completedAt), 'dd MMM yyyy')
                : ''}
            </Text>
            <HStack gap={2}>
              {task?.priority === 'important' && (
                <Image src={ImportantFlag} alt="" w="17px" h="auto" />
              )}
              {task?.status === 'completed' && (
                <Tooltip label="Tap to mark uncompleted" hasArrow>
                  <Flex
                    as="span"
                    p={1}
                    borderRadius="full"
                    transition="all 0.2s ease"
                    _hover={{ bg: 'rgba(95, 191, 150, 0.1)' }}
                    onClick={handleUncompleteTask}>
                    <Icon
                      as={FaCircleCheck}
                      color="brand.900"
                      w="24px"
                      h="24px"
                    />
                  </Flex>
                </Tooltip>
              )}

              {task?.status === 'pending' && (
                <Tooltip
                  variant="business"
                  label="Tap to mark completed"
                  hasArrow>
                  <Flex
                    as="span"
                    p={1}
                    borderRadius="full"
                    transition="all 0.2s ease"
                    _hover={{ bg: 'rgba(95, 191, 150, 0.1)' }}
                    onClick={handleCompleteTask}>
                    <Icon
                      as={FaRegCircle}
                      color="brand.900"
                      w="24px"
                      h="24px"
                    />
                  </Flex>
                </Tooltip>
              )}
            </HStack>
          </HStack>
        </Flex>
      </VStack>
    </Flex>
  );
}
