import { AxiosInstance, AxiosResponse } from 'axios';
import { IQoute, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class IQouteService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createQuote(IQouteRequest: IQoute): Promise<IQoute> {
    const response: AxiosResponse<IQoute> = await this.api.post(
      '/quotes',
      IQouteRequest,
    );
    return response.data;
  }

  public async getQuotes(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<IQoute>> {
    const response: AxiosResponse<PaginatedResults<IQoute>> =
      await this.api.get('/quotes', {
        params: queryParams,
      });
    return response.data;
  }

  public async getQuote(id: string): Promise<IQoute> {
    const response: AxiosResponse<IQoute> = await this.api.get(`/quotes/${id}`);
    return response.data;
  }

  public async updateQuote(
    id: string,
    updates: Partial<IQoute>,
  ): Promise<IQoute> {
    const response: AxiosResponse<IQoute> = await this.api.patch(
      `/quotes/${id}`,
      updates,
    );
    return response.data;
  }

  public async deleteQuote(id: string): Promise<void> {
    await this.api.delete(`/quotes/${id}`);
  }

  public async shareQuote(formData: FormData): Promise<void> {
    await this.api.post(`/quotes/share`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

const _IQouteService = new IQouteService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _IQouteService;
