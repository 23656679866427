import { Button, Flex, Hide, HStack, Icon, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import DropdownField from 'components/fields/DropdownField';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { getQuotes } from 'contexts/redux/quote/quoteSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NoQuotes from './components/NoQuotes';
import NoQuotesCreated from './components/NoQuotesCreated';
import QuoteList from './components/QuoteList';
export default function Quotes() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [search, setSearch] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('');
  const myUser = useMyUser();
  const { quotes } = useSelector((state: RootState) => ({
    quotes: state.quote.qoutes,
  }));

  const handleSort = useCallback(
    (header: 'date' | 'event_name' | 'amount' | 'customer') => {
      if (sortBy === header) {
        setSortBy('');
        return;
      }
      setSortBy(header);
    },
    [sortBy],
  );

  useEffect(() => {
    if (!myUser?.id) {
      return;
    }

    const params: {
      limit: number;
      search?: string;
      projectBy?: string;
      sortBy?: string;
      senderId?: string;
    } = {
      limit: 20,
      projectBy: '-updatedAt',
      senderId: myUser.id,
    };
    if (search) {
      params.search = search;
    }

    if (sortBy) {
      let newSortBy = '';

      switch (sortBy) {
        case 'date':
          newSortBy = '-createdAt';
          break;
        case 'event_name':
          newSortBy = '-eventName';
          break;
        case 'amount':
          newSortBy = '-generalPaymentDetails.totalPayment';
          break;
        case 'customer':
          newSortBy = '-customerDetails.firstName';
          break;
      }
      params.sortBy = newSortBy;
    }
    dispatch(getQuotes(params));
  }, [dispatch, search, sortBy, myUser?.id]);

  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '0' }}
      flexDirection="column"
      gap="8px">
      <Hide above="md">
        <Card gap={5} borderRadius="0">
          <SearchBar
            w="173px"
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
            inputProps={{
              isDisabled: search === '' && quotes.length === 0,
              _disabled: {
                cursor: 'not-allowed',
              },
              borderRadius: '16px',
            }}
            borderRadius="16px"
          />
          <Flex align="center" gap="16px">
            <DropdownField flex="1" placeholder={t('quote_list.sort_by')} />
            <Button
              variant="h1cta"
              onClick={() => navigate('/main/finance/quotations/create')}
              flex="1"
              leftIcon={<Icon as={FaPlus} />}>
              {t('quote_list.create_quote')}
            </Button>
          </Flex>
        </Card>
      </Hide>
      <Card
        gap={5}
        h="calc(100vh - 170px)"
        borderRadius={{ base: 0, md: '30px' }}>
        <Hide below="md">
          <HStack justifyContent="space-between" align="center">
            <Text variant="cardTitle">{t('quote_list.title')}</Text>
            <Flex gap={4}>
              <SearchBar
                w="173px"
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
                inputProps={{
                  isDisabled: search === '' && quotes.length === 0,
                  _disabled: {
                    cursor: 'not-allowed',
                  },
                }}
              />
              <Button
                variant="h1cta"
                onClick={() => navigate('/main/finance/quotations/create')}
                p="18px 38px"
                leftIcon={<Icon as={FaPlus} />}>
                {t('quote_list.create_quote')}
              </Button>
            </Flex>
          </HStack>
        </Hide>
        {quotes.length > 0 && (
          <QuoteList quotes={quotes} handleSort={handleSort} sortBy={sortBy} />
        )}
        {search === '' && quotes.length === 0 && <NoQuotesCreated />}
        {search !== '' && quotes.length === 0 && <NoQuotes />}
      </Card>
    </Flex>
  );
}
