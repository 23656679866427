import { Search2Icon } from '@chakra-ui/icons';
import {
  Flex,
  IconButton,
  Text,
  useToast,
  CircularProgress,
} from '@chakra-ui/react';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  clearSearch,
  createMessage,
  fetchMessages,
  setMessages,
  setSearchQuery,
  setSearchState,
} from 'contexts/redux/messenger/messengerSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LuChevronDown, LuChevronUp, LuX } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import { IMessage } from 'services/@types';
import messageService from 'services/message.api';
import { getMessagePage } from 'contexts/redux/messenger/messengerSlice';

export const ChatSearchBarComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const user = useMyUser();
  const { currentThread, searchState } = useSelector((state: RootState) => ({
    currentThread: state.messenger.currentThread,
    searchState: state.messenger.searchState,
  }));

  const handleCloseSearch = useCallback(() => {
    console.log('🔍 Closing search - clearing query and search state');
    dispatch(setSearchQuery(''));
    console.log('📤 Dispatched: setSearchQuery("")');

    dispatch(clearSearch());
    console.log('📤 Dispatched: clearSearch()');

    dispatch(setSearchState({ isSearching: false }));
    console.log('📤 Dispatched: setSearchState({ isSearching: false })');

    dispatch(
      fetchMessages({ threadId: currentThread.id, sortBy: '-createdAt' }),
    );
    console.log('📤 Dispatched: fetchMessages', { threadId: currentThread.id });
  }, [dispatch, currentThread?.id]);

  const initiateSearch = useCallback(async () => {
    console.log('🔍 Initiating search:', {
      query: searchState.query,
      threadId: currentThread?.id,
    });

    if (!searchState.query?.trim() || !currentThread?.id) {
      console.log('❌ Search cancelled - Empty query or no thread ID');
      return;
    }

    try {
      console.log('🔄 Setting search state to searching...', {
        query: searchState.query,
      });
      dispatch(setSearchState({ isSearching: true, query: searchState.query }));
      console.log('📤 Dispatched: setSearchState', {
        isSearching: true,
        query: searchState.query,
      });

      const response = await messageService.searchMessages({
        threadId: currentThread.id,
        query: searchState.query,
      });
      if (response.results.length === 0) {
        toast({
          title: t('messenger.search.no_results'),
          status: 'warning',
        });
        dispatch(setSearchState({ isSearching: false }));
        return;
      }
      console.log('✅ Search results received:', {
        totalResults: response.results.length,
        firstResultId: response.results[0]?.id,
      });

      try {
        console.log(
          '🔄 Fetching message page for first result...',
          response.results?.[0]?.text,
        );
        const currentMessagePage = await messageService.getMessagePage({
          threadId: currentThread.id,
          messageId: response.results?.[0]?.id,
        });
        console.log('✅ Message page received:', {
          messagesCount: currentMessagePage.results.length,
        });
        dispatch(setMessages(currentMessagePage.results));
        console.log('📤 Dispatched: setMessages', {
          messageCount: currentMessagePage.results.length,
        });
      } catch (error) {
        console.error('❌ Error getting message page:', error);
        toast({
          title: t('messenger.search.error'),
          description: t('messenger.search.page_error'),
          status: 'error',
        });
      }

      if (response.results.length > 0) {
        console.log('✅ Updating search state with results');
        dispatch(
          setSearchState({
            results: response.results,
            total: response.totalResults,
            currentIndex: 0,
          }),
        );
        console.log('📤 Dispatched: setSearchState', {
          resultsCount: response.results.length,
          total: response.totalResults,
          currentIndex: 0,
        });
        setTimeout(() => {
          const messageElement = document.getElementById(
            `message-${response.results[0].id}`,
          );
          console.log('🔄 Scrolling to message:', {
            messageId: response.results[0].id,
            elementFound: !!messageElement,
          });
          messageElement?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 120);
      } else {
        console.log('ℹ️ No search results found');
      }
    } catch (error) {
      console.error('❌ Search error:', error);
      toast({
        title: t('messenger.search.error'),
        status: 'error',
      });
    }
  }, [searchState.query, currentThread?.id, dispatch, toast, t]);

  const handleInsertDummyMessages = useCallback(() => {
    console.log('🔄 Inserting dummy messages');

    const subjects = [
      'The cat',
      'My dog',
      'The monkey',
      'That bird',
      'The elephant',
    ];
    const actions = ['ate', 'chased', 'found', 'watched', 'played with'];
    const fruits = [
      'an apple',
      'a banana',
      'some oranges',
      'a mango',
      'some berries',
    ];
    const locations = [
      'in the park',
      'at home',
      'near the beach',
      'in the garden',
      'by the lake',
    ];
    const projects = [
      'working on the app',
      'building a website',
      'planning the trip',
      'writing code',
      'designing UI',
    ];
    const activities = [
      'went hiking',
      'visited friends',
      'had a picnic',
      'explored the city',
      'took photos',
    ];

    const generateRandomSentence = () => {
      const type = Math.floor(Math.random() * 3);
      if (type === 0) {
        return `${subjects[Math.floor(Math.random() * subjects.length)]} ${
          actions[Math.floor(Math.random() * actions.length)]
        } ${fruits[Math.floor(Math.random() * fruits.length)]} ${
          locations[Math.floor(Math.random() * locations.length)]
        }`;
      } else if (type === 1) {
        return `I've been ${
          projects[Math.floor(Math.random() * projects.length)]
        } today`;
      } else {
        return `Yesterday we ${
          activities[Math.floor(Math.random() * activities.length)]
        } ${locations[Math.floor(Math.random() * locations.length)]}`;
      }
    };

    Array.from({ length: 100 }).forEach((_, index) => {
      const message: IMessage = {
        senderId: user.id,
        threadId: currentThread.id,
        text: generateRandomSentence(),
      };
      console.log('Generated message:', message.text);
      dispatch(
        createMessage({
          ...message,
          threadId: currentThread.id,
        }),
      );
    });
  }, [currentThread?.id, dispatch, user?.id]);

  const handleSearchToggle = useCallback(() => {
    console.log('🔄 Search toggle clicked:', {
      isSearchBarOpened: searchState.isSearchBarOpened,
      hasQuery: !!searchState.query.trim(),
    });

    if (!searchState.isSearchBarOpened) {
      console.log('📂 Opening search bar');
      dispatch(setSearchState({ isSearchBarOpened: true }));
      console.log('📤 Dispatched: setSearchState', { isSearchBarOpened: true });
    } else if (searchState.query.trim()) {
      console.log('🔍 Initiating search with existing query');
      initiateSearch();
    } else {
      console.log('🔍 Closing search - no query');
      handleCloseSearch();
    }
  }, [
    dispatch,
    handleCloseSearch,
    initiateSearch,
    searchState.isSearchBarOpened,
    searchState.query,
  ]);

  const handlePreviousResult = useCallback(async () => {
    if (!currentThread?.id || !searchState.results.length) {
      return;
    }

    const prevIndex =
      (searchState.currentIndex - 1 + searchState.results.length) %
      searchState.results.length;
    const prevMessage = searchState.results[prevIndex];

    try {
      dispatch(setSearchState({ currentIndex: prevIndex }));

      await dispatch(
        getMessagePage({
          threadId: currentThread.id,
          messageId: prevMessage.id,
        }),
      ).unwrap();

      const messageElement = document.getElementById(
        `message-${prevMessage.id}`,
      );
      messageElement?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
    } catch (error) {
      toast({
        title: t('messenger.search.error'),
        description: t('messenger.search.page_error'),
        status: 'error',
      });
    }
  }, [
    currentThread?.id,
    searchState.results,
    searchState.currentIndex,
    dispatch,
    toast,
    t,
  ]);

  const handleNextResult = useCallback(async () => {
    if (!currentThread?.id || !searchState.results.length) {
      return;
    }

    const nextIndex =
      (searchState.currentIndex + 1) % searchState.results.length;
    const nextMessage = searchState.results[nextIndex];

    try {
      dispatch(setSearchState({ currentIndex: nextIndex }));

      await dispatch(
        getMessagePage({
          threadId: currentThread.id,
          messageId: nextMessage.id,
        }),
      ).unwrap();

      setTimeout(() => {
        const messageElement = document.getElementById(
          `message-${nextMessage.id}`,
        );
        messageElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 120);
    } catch (error) {
      toast({
        title: t('messenger.search.error'),
        description: t('messenger.search.page_error'),
        status: 'error',
      });
    }
  }, [
    currentThread?.id,
    searchState.results,
    searchState.currentIndex,
    dispatch,
    toast,
    t,
  ]);

  const renderSearchContent = useCallback(() => {
    if (searchState.isSearching) {
      return (
        <Flex gap={2} alignItems="center">
          <Flex gap={1} alignItems="center">
            <IconButton
              icon={<LuChevronDown size={18} />}
              aria-label="previous result"
              variant="ghost"
              size="sm"
              color="#8E7A70"
              h="34px"
              onClick={handlePreviousResult}
              isDisabled={searchState.isNavigating}
            />
            <Text fontSize="sm" color="gray.600" minW="45px" textAlign="center">
              {searchState.isNavigating ? (
                <CircularProgress size="20px" isIndeterminate color="#8E7A70" />
              ) : (
                `${searchState.currentIndex + 1}/${searchState.results.length}`
              )}
            </Text>
            <IconButton
              icon={<LuChevronUp size={18} />}
              aria-label="next result"
              variant="ghost"
              size="sm"
              color="#8E7A70"
              h="34px"
              onClick={handleNextResult}
              isDisabled={searchState.isNavigating}
            />
          </Flex>
          <IconButton
            icon={<LuX size={24} />}
            aria-label="close search"
            variant="ghost"
            color="#8E7A70"
            h="34px"
            onClick={handleCloseSearch}
          />
        </Flex>
      );
    }
    console.log('🔄 Search content render:', {
      isSearchBarOpened: searchState.isSearchBarOpened,
      query: searchState.query,
    });
    if (searchState.isSearchBarOpened) {
      return (
        <Flex gap={2} alignItems="center">
          <SearchBar
            inputProps={{
              value: searchState.query,
            }}
            onChange={(e: any) => {
              dispatch(setSearchQuery(e.target.value));
              console.log('📤 Dispatched: setSearchQuery', {
                value: e.target.value,
              });
            }}
            onKeyPress={(e) => e.key === 'Enter' && initiateSearch()}
            placeholder={t('messenger.search.placeholder')}
          />
          <IconButton
            icon={<Search2Icon />}
            color="#8E7A70"
            w="34px"
            h="34px"
            cursor="pointer"
            variant="ghost"
            aria-label="search"
            onClick={handleSearchToggle}
          />
          <IconButton
            icon={<LuX size={24} />}
            aria-label="close search"
            variant="ghost"
            color="#8E7A70"
            h="34px"
            onClick={handleCloseSearch}
          />
        </Flex>
      );
    }

    return (
      <IconButton
        icon={<Search2Icon />}
        color="#8E7A70"
        w="34px"
        h="34px"
        cursor="pointer"
        variant="ghost"
        aria-label="search"
        onClick={handleSearchToggle}
      />
    );
  }, [
    searchState.isSearching,
    searchState.isSearchBarOpened,
    searchState.currentIndex,
    searchState.results.length,
    searchState.query,
    searchState.isNavigating,
    handleSearchToggle,
    handlePreviousResult,
    handleNextResult,
    handleCloseSearch,
    t,
    dispatch,
    initiateSearch,
  ]);

  return (
    <Flex gap={2} alignItems="center">
      {renderSearchContent()}
    </Flex>
  );
};
