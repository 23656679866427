import { Flex, Icon, Text } from '@chakra-ui/react';
import { BankNoteIcon } from 'components/icons/BankNoteIcon';
import { format } from 'date-fns';
import { formatNumber } from 'helpers/formatNumber';
import { useMemo } from 'react';
import { AiOutlinePieChart } from 'react-icons/ai';
import { FiCalendar } from 'react-icons/fi';
import { IQoute } from 'services/@types';

interface IPaymentItemProps {
  instalment: IQoute['paymentInInstalments']['payment'][0];
  formattedCurrency: string;
  index: number;
  length: number;
}

export default function PaymentItem(props: IPaymentItemProps) {
  const { instalment, formattedCurrency, index, length } = props;

  const formattedDate = useMemo(() => {
    if (!instalment.date) return '-';
    return format(new Date(instalment.date), 'dd MMM, yyyy');
  }, [instalment.date]);

  const formattedAmount = useMemo(() => {
    if (!instalment.amount) return '-';
    return formattedCurrency + formatNumber(instalment.amount).toLocaleString();
  }, [instalment.amount, formattedCurrency]);

  return (
    <Flex
      bg="#F6F6F6"
      borderRadius="16px"
      justify="space-between"
      p="10px 12px">
      <Flex alignItems="center" gap="8px">
        <Icon as={AiOutlinePieChart} color="#060518" w="15px" h="15px" />
        <Text variant="previewQuotePaymentItem">
          {index + 1}\{length}
        </Text>
      </Flex>
      <Flex alignItems="center" gap="8px">
        <Icon as={FiCalendar} color="#060518" w="15px" h="15px" />
        <Text variant="previewQuotePaymentItem">{formattedDate}</Text>
      </Flex>
      <Flex alignItems="center" gap="8px">
        <Icon as={BankNoteIcon} color="#060518" w="15px" h="15px" />
        <Text variant="previewQuotePaymentItem">{formattedAmount}</Text>
      </Flex>
    </Flex>
  );
}
