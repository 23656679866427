import { createIcon } from '@chakra-ui/react';

export const BankNoteIcon = createIcon({
  displayName: 'BankNoteIcon',
  viewBox: '0 0 16 17',
  path: (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.99967 7.45109V10.1178M11.9997 6.11776V8.78442M11.333 2.78442C12.9655 2.78442 13.8484 3.03426 14.2878 3.22805C14.3463 3.25386 14.3755 3.26676 14.4599 3.34734C14.5106 3.39564 14.6029 3.53735 14.6267 3.60315C14.6663 3.71292 14.6663 3.77292 14.6663 3.89292V11.0585C14.6663 11.6644 14.6663 11.9673 14.5755 12.123C14.4831 12.2814 14.3939 12.355 14.221 12.4159C14.0509 12.4757 13.7077 12.4098 13.0211 12.2779C12.5406 12.1855 11.9707 12.1178 11.333 12.1178C9.33301 12.1178 7.33301 13.4511 4.66634 13.4511C3.03387 13.4511 2.15092 13.2012 1.7116 13.0075C1.65309 12.9817 1.62384 12.9687 1.53941 12.8882C1.4888 12.8399 1.39642 12.6982 1.37265 12.6324C1.33301 12.5226 1.33301 12.4626 1.33301 12.3426L1.33301 5.17699C1.33301 4.57115 1.33301 4.26823 1.42386 4.11252C1.51628 3.95413 1.6054 3.8805 1.77838 3.81963C1.94843 3.75979 2.2917 3.82575 2.97823 3.95766C3.45875 4.04998 4.02866 4.11776 4.66634 4.11776C6.66634 4.11776 8.66634 2.78442 11.333 2.78442ZM9.66634 8.11776C9.66634 9.03823 8.92015 9.78442 7.99967 9.78442C7.0792 9.78442 6.33301 9.03823 6.33301 8.11776C6.33301 7.19728 7.0792 6.45109 7.99967 6.45109C8.92015 6.45109 9.66634 7.19728 9.66634 8.11776Z"
        stroke="#060518"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});
