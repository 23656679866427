import {
  Button,
  HStack,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
  useToast,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import TagsField from 'components/fields/TagsField';
import TextField from 'components/fields/TextField';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { createInvite } from 'contexts/redux/invite/inviteSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdInfoOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Invite } from 'services/@types';
import { CheckboxItem } from './CheckboxItem';

const AccessLevels = ['admin', 'manager', 'viewer'];
export const AddSeatModal: React.FC = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [seatProps, setSeatProps] = useState<Partial<Invite>>({});
  const { loading } = useSelector((state: RootState) => state.invites);
  const dispatch = useDispatch<AppDispatch>();
  const business = useMyBusiness();
  const dialog = useSelector((state: RootState) => state.dialogs.addSeatDialog);
  const toast = useToast();
  const handleValueChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSeatProps((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    },
    [],
  );
  useEffect(() => {
    if (dialog?.item) {
      onOpen();
      setSeatProps(dialog.item);
    } else {
      onClose();
    }
  }, [dialog, onOpen, onClose, setSeatProps]);

  const handleSendInvite = useCallback(async () => {
    const { payload } = await dispatch(
      createInvite({
        ...seatProps,
        businessID: business?.id,
        type: 'seat',
      } as Invite),
    );
    console.log('#Redux createInvite response ->', payload);
    const response = (payload as any).response;
    if (response.status === 409) {
      toast({
        title: t(response.title),
        description: t(response.content),
        status: 'error',
      });
    } else if (response.status >= 400) {
      toast({
        title: t('error_messages.400'),
        // description: t('error_messages.400'),
        status: 'error',
      });
    } else {
      toast({
        title: t('invite_seat.invite_sent'),
        status: 'success',
        variant: 'main',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
    }
  }, [business?.id, dispatch, seatProps, t, toast]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="740px" borderRadius={'3xl'} pb={10} mx={1}>
        <ModalHeader p={5}>
          <HStack w="100%" gap={2}>
            <VStack align="start" spacing={0}>
              <Text fontSize="2xl">{t('the_office.add_seat_modal.title')}</Text>
              <Text
                fontSize={{ base: 'md', sm: 'sm', md: 'md' }}
                fontWeight={'300'}>
                {t('the_office.add_seat_modal.subtitle')}
              </Text>
            </VStack>
            <Spacer />
            <Button
              isLoading={loading}
              onClick={handleSendInvite}
              variant="h1cta"
              px={10}
              //  onClick={handleSaveClicked}
            >
              {t('the_office.add_seat_modal.save')}
            </Button>
            <ModalCloseButton top={0} right={0} position={'relative'} />
          </HStack>
        </ModalHeader>
        <HStack
          align="start"
          gap={10}
          p={5}
          pe={{ base: 5, md: 12 }}
          wrap="wrap">
          <VStack gap={3} flex={1} maxW={{ base: '100%', md: '310px' }}>
            <InputField
              name="email"
              w="full"
              value={seatProps?.email}
              label={t('the_office.add_seat_modal.email')}
              onChange={handleValueChanged}
            />
            <InputField
              value={seatProps?.fullName}
              w="full"
              name="fullName"
              label={t('the_office.add_seat_modal.full_name')}
              onChange={handleValueChanged}
            />
            <InputField
              w="full"
              value={seatProps?.jobTitle}
              name="jobTitle"
              label={t('the_office.add_seat_modal.job_title')}
              onChange={handleValueChanged}
            />

            <TagsField
              maxH={120}
              placeholderTags={seatProps.tags}
              onTagsChange={(tags: string[]) => {
                setSeatProps((prev) => ({
                  ...prev,
                  tags: tags.filter((tag) => tag.length > 0),
                }));
              }}
              w="100%"
              label={t('the_office.add_seat_modal.tags')}
            />
          </VStack>
          <VStack
            align="start"
            flex={1}
            gap={0}
            minW={310}
            maxW={{ base: '100%', md: '310px' }}>
            <TextField
              w="100%"
              minW={310}
              value={seatProps?.serviceDescription}
              minH={120}
              onChange={handleValueChanged}
              label={t('the_office.add_seat_modal.service_description')}
              name="serviceDescription"
            />
            <VStack align="start" gap={2} w="100%">
              <HStack>
                <Text fontSize={'xl'} fontWeight="bold">
                  {t('the_office.add_seat_modal.account_restrictions')}
                </Text>
                <Tooltip
                  hasArrow
                  variant="business"
                  arrowShadowColor="#00000010"
                  label={t(
                    'the_office.add_seat_modal.account_restrictions_tooltip',
                  )}
                  aria-label="Add or remove members to your account. manage account permissions">
                  <IconButton
                    aria-label="info"
                    bg="transparent"
                    fontSize={'2xl'}
                    icon={<MdInfoOutline />}
                    color="brand.600"
                  />
                </Tooltip>
              </HStack>
              <Stack gap={4} direction={'column'} w="100%">
                {AccessLevels.map((role) => (
                  <CheckboxItem
                    label={t(`the_office.add_seat_modal.roles.${role}.label`)}
                    description={t(
                      `the_office.add_seat_modal.roles.${role}.description`,
                    )}
                    value={role}
                    onChange={() =>
                      handleValueChanged({
                        target: { name: 'role', value: role },
                      } as any)
                    }
                    checked={role === seatProps.role}
                  />
                ))}
              </Stack>
            </VStack>
          </VStack>
        </HStack>

        {/* <Button
                    alignSelf="center"
                    variant="outlineRed"
                // onClick={handleSaveClicked}
                >
                    {t('the_office.add_seat_modal.delete_team_member')}
                </Button> */}
      </ModalContent>
    </Modal>
  );
};
