import { useToast } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { closeSplashDialog } from 'contexts/redux/dialog/dialogsSlice';
import {
  createEvent,
  getEvent,
  updateEvent,
} from 'contexts/redux/event/eventSlice';
import store, { RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IEvent } from 'services/@types';
const requiredFields = ['name', 'type', 'location'];
interface ICreateEventResult {
  handleChange: (key: string, value: any) => void;
  handleCreateUpdateEvent: () => void;
  event: Record<string, any>;
  errorList: string[];
}
export default function useCreateEvent(): ICreateEventResult {
  const params = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const eventData = useSelector((state: RootState) => state.event.event);
  const [event, setEvent] = useState<Record<string, any>>({
    dateAndTime: new Date(),
    contactDetails: [{}],
  });
  const [errorList, setErrorList] = useState<string[]>([]);
  const { user } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    // find event calendar id and add it to the event as default calendar id
    const calendar = store
      .getState()
      .calendars.calendars.find(
        (calendar) => calendar.isSystem && calendar.name === 'events',
      );
    if (calendar) {
      setEvent((prev) => ({ ...prev, calendarId: calendar.id }));
    }
  }, []);
  const validateEvent = useCallback(() => {
		console.log('log event ', event)
    const result = requiredFields.filter((field) => { 
      if (field.includes('.')) { 
        const [parent, child] = field.split('.'); 
        return !event[parent]?.[child];
      } 
      return typeof event[field] === 'string' ? !event[field] : !event[field]?.value; 
    });
    if (result.length) {
      setErrorList(result);
      return true;
    }
    return false;
  }, [event]);
  const handleCreateUpdateEvent = useCallback(async () => {
    const isError = validateEvent();
    if (isError) {
      console.log('errorList ->', errorList);
      return;
    }
    let response;
    if (params?.id) {
      const updates: any = {};
      // compare the event data with the eventData from the store
      for (const key in event) {
        if (event.hasOwnProperty(key)) {
          if (event[key] !== eventData[key as keyof IEvent]) {
            updates[key] = event[key];
          }
        }
      }
      response = await dispatch(
        updateEvent({
          id: params.id,
          updates,
        }),
      );
    } else {
      if (event.calendarId === null) {
        delete event.calendarId;
      }
      response = await dispatch(
        createEvent({ ...event, curator: user?.id } as IEvent),
      );
    }
    if (response?.payload) {
      setEvent({});
      toast({
        variant: 'main',
        title: t(
          params?.id
            ? 'create_event.toast.event_updated'
            : 'create_event.toast.event_added',
        ),
      });
      navigate(`/main/event/${response?.payload?.id}`);
      queryClient.invalidateQueries({
        queryKey: ['events'],
      });
    }
    if (response?.error) {
      dispatch(closeSplashDialog());
    }
  }, [
    validateEvent,
    params.id,
    errorList,
    dispatch,
    event,
    eventData,
    user?.id,
    toast,
    t,
    navigate,
    queryClient,
  ]);
  const handleChange = useCallback(
    (key: string, value: any, errorKey?: string) => {
      console.log('handle change ->', key, value);
      if (errorList.includes(key)) {
        setErrorList((prev) => prev.filter((error) => error !== key));
      }
      if (errorKey && errorList.includes(errorKey)) {
        setErrorList((prev) => prev.filter((error) => error !== errorKey));
      }
      console.log('key: ', key, ' value: ', value);
      setEvent((prev) => ({ ...prev, [key]: value }));
    },
    [errorList],
  );
  useEffect(() => {
    if (params?.id) {
      const fetchEvent = async () => {
        const event = await dispatch(getEvent(params?.id));
        if (event.payload) {
          const eventData = { ...event.payload };
          if (eventData.dateAndTime) {
            eventData.dateAndTime = new Date(event.payload.dateAndTime);
          }
          setEvent(eventData);
        }
      };
      fetchEvent();
    }
  }, [params?.id, dispatch]);
  return { handleChange, handleCreateUpdateEvent, event, errorList };
}
