import { Flex, Text } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

interface INoteAndTermsProps {
  comment: string;
  paymentTerms: string;
  legalText: string;
}

export default function NoteAndTerms(props: INoteAndTermsProps) {
  const { comment, paymentTerms, legalText } = props;
  const { t } = useTranslation();

  if (!comment && !paymentTerms && !legalText) {
    return <Flex flex={2} />;
  }

  return (
    <Flex flex={2} flexDir="column" gap="36px">
      <Flex flexDir="column" gap="15px" display={comment ? 'flex' : 'none'}>
        <Text variant="previewQuoteTitle">{t('quote_preview.note')}</Text>
        <Text variant="previewQuoteNote">{comment}</Text>
      </Flex>
      <Flex
        flexDir="column"
        gap="8px"
        bg="#93C3B333"
        borderRadius="16px"
        p="8px 12px"
        display={paymentTerms ? 'flex' : 'none'}>
        <Text variant="previewQuotePaymentTermsTitle">
          {t('quote_preview.payment_terms')}:
        </Text>
        <Text variant="previewQuotePaymentTermsNote">{paymentTerms}</Text>
      </Flex>
      <Text
        variant="previewQuoteCopyright"
        display={legalText ? 'flex' : 'none'}>
        {legalText}
      </Text>
    </Flex>
  );
}
