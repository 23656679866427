import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import {
  setQuoteSharingDialog,
  showSplashDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const QuoteThreeDotsMenu = memo(({ id }: { id: string }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const handleDownloadPdf = useCallback(
    (e: any) => {
      e.stopPropagation();
      dispatch(showSplashDialog({ title: 'dialogs.splash.please_wait' }));
      setTimeout(() => {
        navigate(`/main/finance/quotations/preview/${id}?download=true`);
      }, 400);
    },
    [navigate, id, dispatch],
  );

  return (
    <Menu>
      <MenuButton onClick={(e) => e.stopPropagation()}>
        <Icon as={FiMoreHorizontal} color="#93C3B3" w="24px" h="24px" />
      </MenuButton>
      <MenuList>
        <MenuItem
          h="44px"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/main/finance/quotations/preview/${id}`);
          }}>
          <Text variant="eventTabButtonTitle">{t('quote_list.view')}</Text>
        </MenuItem>
        <MenuItem
          h="44px"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              setQuoteSharingDialog({
                item: {
                  quoteId: id,
                },
              }),
            );
          }}>
          <Text variant="eventTabButtonTitle">{t('quote_list.share')}</Text>
        </MenuItem>
        <MenuItem h="44px" onClick={handleDownloadPdf}>
          <Text variant="eventTabButtonTitle">{t('quote_list.download')}</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
});
