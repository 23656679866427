import { Flex, Hide, Text, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IQoute } from 'services/@types';
import ProductListItem from './ProductListItem';

export const headers = ['item', 'quantity', 'rate', 'amount'];

export type QuoteProduct = IQoute['products'];

interface IProductsListProps {
  products: QuoteProduct;
  currency: string;
}

export default function ProductsList(props: IProductsListProps) {
  const { products, currency } = props;
  const { t } = useTranslation();

  const formattedCurrency = useMemo(() => {
    if (currency === 'USD') return '$';
    return '₪';
  }, [currency]);

  return (
    <VStack w="100%" spacing={0} align="stretch">
      <Flex
        borderBottom="1px solid #E9EDF7"
        py={4}
        px={6}
        display={{ base: 'none', md: 'flex' }}>
        {headers.map((header, index) => (
          <Flex key={header} flex={index === 0 ? 2 : 1} align="center">
            <Text variant="previewQuoteProductHeader">
              {t(`quote_preview.${header}`)}
            </Text>
          </Flex>
        ))}
      </Flex>

      <VStack spacing={0} align="stretch">
        {products?.map((product, index) => (
          <ProductListItem
            product={product}
            key={index}
            isLast={index === products?.length - 1}
            formattedCurrency={formattedCurrency}
          />
        ))}
      </VStack>
    </VStack>
  );
}
