import {
  Divider,
  Flex,
  Icon,
  IconButton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { setQuoteSharingDialog } from 'contexts/redux/dialog/dialogsSlice';
import { format } from 'date-fns';
import { formatNumber } from 'helpers/formatNumber';
import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FiDownload } from 'react-icons/fi';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { IoMdShare } from 'react-icons/io';
import { IoLocationOutline } from 'react-icons/io5';
import { MdEdit } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import EventService from 'services/event.api';

interface IContactDetailsProps {
  createdAt: Date;
  quoteNumber: number;
  clientName: string;
  numberOfGuests: number;
  location: string;
  totalAmount: number;
  currency: string;
  eventId: string;
  handleDownload: () => Promise<boolean>;
  isDownloading: boolean;
  setIsDownloading: (value: boolean) => void;
}

export default function ContactDetails(props: IContactDetailsProps) {
  const {
    createdAt,
    quoteNumber,
    clientName,
    numberOfGuests,
    location,
    totalAmount,
    currency,
    eventId,
    handleDownload,
    isDownloading,
    setIsDownloading,
  } = props;
  const { t } = useTranslation();
  const toaster = useToast();
  const toasterRef = useRef<any>(null);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch<any>();

  const eventResult = useQuery({
    queryKey: [`getEvent-${eventId}`],
    queryFn: () => EventService.getEvent(eventId),
    enabled: !!eventId,
  });

  const eventName = useMemo(
    () => eventResult?.data?.name,
    [eventResult?.data?.name],
  );

  const memoizedCreatedAt = useMemo(() => {
    return createdAt ? format(new Date(createdAt), 'MMM d, yyyy') : '';
  }, [createdAt]);

  const memoizedTotalAmount = useMemo(() => {
    if (!totalAmount) return '';

    if (currency === 'USD') return `$${formatNumber(totalAmount)}`;

    return `₪${formatNumber(totalAmount)}`;
  }, [totalAmount, currency]);

  const handleDownloadPdf = useCallback(async () => {
    toasterRef.current = toaster({
      title: t('quote_preview.uploading'),
      description: t('quote_preview.please_wait'),
      variant: 'main',
      duration: 100000,
      position: 'top-right',
    });
    setIsDownloading(true);
    setTimeout(async () => {
      const result = await handleDownload();

      if (result) {
        setIsDownloading(false);
        toaster.update(toasterRef.current, {
          title: t('quote_preview.successfully_uploaded'),
          variant: 'main',
          position: 'top-right',
        });
        setTimeout(() => {
          toaster.close(toasterRef.current);
        }, 1000);
      }
    }, 1000);
  }, [handleDownload, setIsDownloading, t, toaster]);

  const handleUpdateQuote = useCallback(() => {
    navigate(`/main/finance/quotations/${params?.id}`);
  }, [navigate, params?.id]);

  const handleShareQuote = useCallback(() => {
    dispatch(
      setQuoteSharingDialog({
        item: {
          quoteId: params?.id,
        },
      }),
    );
  }, [dispatch, params?.id]);

  return (
    <Flex flexDirection="column" w="100%" gap="24px">
      <Flex w="100%" justifyContent="space-between" alignItems="start">
        <Flex flexDirection="column">
          <Text variant="previewQuoteContactTitle">
            {t('quote_preview.quote')}
          </Text>
          <Text variant="createQuoteCheckboxText">{memoizedCreatedAt}</Text>
          <Text variant="previewQuoteContactNumber">
            {quoteNumber ? `#${quoteNumber}` : ''}
          </Text>
        </Flex>
        {!isDownloading && (
          <Flex alignItems="center" gap="8px">
            <IconButton
              aria-label="edit"
              icon={<MdEdit color="#fff" />}
              w="32px"
              h="32px"
              minH="32px"
              minW="32px"
              bg="#ACACAC"
              onClick={handleUpdateQuote}
            />
            <IconButton
              aria-label="edit"
              icon={<FiDownload color="#fff" />}
              onClick={handleDownloadPdf}
              w="32px"
              h="32px"
              minH="32px"
              minW="32px"
              bg="#ACACAC"
            />
            <IconButton
              aria-label="edit"
              icon={<IoMdShare color="#fff" />}
              w="32px"
              h="32px"
              minW="32px"
              minH="32px"
              bg="#ACACAC"
              onClick={handleShareQuote}
            />
          </Flex>
        )}
      </Flex>
      <Flex
        border="1px solid #00000033"
        borderRadius="20px"
        p={{ base: '12px', md: '24px' }}
        gap={{ base: '16px', md: '0px' }}
        justifyContent="space-between"
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'start', md: 'end' }}>
        <Flex flexDirection="column" gap="8px">
          <Text variant="previewQuoteContactLabel">
            {t('quote_preview.quote_for')}
          </Text>
          <Text variant="taskHistoryTitle">{clientName}</Text>
          <Text variant="previewQuoteEventName">{eventName}</Text>
          <Flex alignItems="center" gap="12px">
            <Flex alignItems="center" gap="8px">
              <Icon as={HiOutlineMailOpen} color="general.100" />
              <Text variant="previewQuoteContactLabelSmall">
                {t('create_event.modals.found_event.invited', {
                  numberOfGuests: numberOfGuests,
                })}
              </Text>
            </Flex>
            <Flex alignItems="center" gap="8px">
              <Icon as={IoLocationOutline} color="general.100" />
              <Text
                variant="previewQuoteContactLabelSmall"
                wordBreak={'break-word'}>
                {location}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Divider
          borderColor="#00000033"
          display={{ base: 'block', md: 'none' }}
        />
        <Flex flexDirection="column" gap="16px">
          <Text variant="previewQuoteContactLabel">
            {t('quote_preview.total_amount')}
          </Text>
          <Text variant="previewQuoteTotalAmount">{memoizedTotalAmount}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
